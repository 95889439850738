import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/global.scss';
import '../styles/main.scss';
import Typewriter from 'typewriter-effect';
import GraphemeSplitter from 'grapheme-splitter';
import lily from '../images/author.png';
const MainPage = () => {
  const stringSplitter = (string) => {
    const splitter = new GraphemeSplitter();
    return splitter.splitGraphemes(string);
  };

  return (
    <div id="introContainer">
      <div id="header" className="flex" data-sal="fade">
        <div id="authorImg">
          <img src={lily} alt="Lily Lou" style={{ maxHeight: '90vh' }} />
        </div>
        <div id="authorBio">
          <h1 id="main" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
            Hello, I'm Lily.
          </h1>
          <div className="typeDiv" data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
            <h3 className="type"> I like&nbsp;</h3>
            <h3 className="type">
              <Typewriter
                options={{
                  strings: [
                    ' setting high scores in 2048 🕹👾',
                    " keeping track of places I've been with Google map pins 🗺️",
                    ' learning about democracy and politics 🗞🗳',
                    ' joining book clubs 📚',
                    ' splurging on ice cream 🍦🃏',
                    ' making Spotify playlists 🔈'
                  ],
                  autoStart: true,
                  loop: true,
                  delay: 100,
                  stringSplitter
                }}
              />
            </h3>
          </div>
          <h3 data-sal="fade" data-sal-delay="300" data-sal-easing="ease" className="description">
            Currently, I'm working as a developer at Rest of World, web scraping for elections with the
            Associated Press and occasionally writing about tech and culture.{' '}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
